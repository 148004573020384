export interface OrderDetails {
  orderCreationDate: Date;
  customer: Customer;
  customerRef?: string; // Order reference
  shippingMethod: string; // smDay, smNight, smPickUpAtCounter
  fixedDeliveryDate?: Date;
  deliveryAddress: Address;
  orderlines: Orderline[];
}

export interface Customer {
  customerNo: string;
  invoiceEmailAddress: string;
  invoiceAddress: Address;
}

export interface Address {
  company: string;
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  telephoneNumber: string;
  emailAddress: string;
}

export interface Orderline {
  rowNumber: number;
  lineIDSource: string;
  item: Item;
  quantity: number;
  minOrderQuantity: number;
  maxOrderQuantity: number;
  stepQuantity: number;
  orderlineRef?: string;
  validationMessage?: string;
}

export interface Item {
  sku: string;
  name: string;
}

export const EmptyOrderline = (): Orderline => ({
  rowNumber: 1,
  lineIDSource: '10',
  item: {
    sku: '',
    name: '',
  },
  quantity: 0,
  minOrderQuantity: 1,
  maxOrderQuantity: 10000,
  stepQuantity: 1,
  orderlineRef: '',
  validationMessage: '',
});

export const EmptyOrderDetails = (): OrderDetails => ({
  orderCreationDate: new Date(),
  customer: {
    customerNo: '',
    invoiceEmailAddress: '',
    invoiceAddress: {
      company: '',
      firstName: '',
      lastName: '',
      address: '',
      postalCode: '',
      city: '',
      country: '',
      telephoneNumber: '',
      emailAddress: '',
    },
  },
  deliveryAddress: {
    company: '',
    firstName: '',
    lastName: '',
    address: '',
    postalCode: '',
    city: '',
    country: '',
    telephoneNumber: '',
    emailAddress: '',
  },
  shippingMethod: '',
  orderlines: [EmptyOrderline()],
});
