<div class="d-flex align-items-center">
  <div *ngIf="atpResponseItem != null; else atpButton">
    <div *ngFor="let info of atpResponseItem.atpInfo">
      <div
        *ngIf="
          info.quantity > 0 && info.expectedDeliveryDate;
          else articleNotFound
        "
      >
        <div>
          <p class="m-0">
            Aantal: {{ info.quantity }} {{ units }}
          </p>
          <p class="m-0">
            Verwachte leverdatum:
            {{ info.expectedDeliveryDate | date : "shortDate" }}
          </p>
        </div>
      </div>
      <ng-template #articleNotFound>
        <p class="text-danger">Artikel kon niet gevonden worden</p>
      </ng-template>
    </div>
  </div>
  <ng-template #atpButton>
    <button
      type="button"
      class="btn btn-outline-primary btn-sm me-3"
      (click)="getAtpInfo()"
      (mouseover)="setIconColor('white')"
      (mouseleave)="setIconColor('primary-color')"
      [disabled]="isLoading || !!!sku || !!!quantity"
    >
      <app-custom-icon
        [name]="'atp-icon'"
        [classes]="'fa-2x ' + iconColor + (isLoading ? ' fa fa-spin ' : '')"
      ></app-custom-icon>
    </button>
    <div *ngIf="errorOccurred" class="alert alert-danger mb-0">
      Er is iets mis gegaan. Probeer het later opnieuw.
    </div>
  </ng-template>
</div>
