import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-new-salesorder-info',
  templateUrl: './new-salesorder-info.component.html',
  styleUrls: ['./new-salesorder-info.component.scss'],
})
export class NewSalesorderInfoComponent implements OnInit {
  @Input() info!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (!this.info.get('freshdeskTicketNumber') || !this.info.get('reason')) {
      this.info = this.fb.group({
        freshdeskTicketNumber: [
          '',
          [Validators.required, Validators.pattern(/^[0-9]+$/)],
        ],
        reason: ['', Validators.required],
        orderReference: [''],
        freshdeskNote: [''],
      });
    }
  }

  createInfo(
    freshdeskTicketNumber: number,
    reason: string,
    orderReference: string,
    freshdeskNote: string
  ): FormGroup {
    return this.fb.group({
      freshdeskTicketNumber: [
        freshdeskTicketNumber,
        [Validators.required, Validators.pattern(/^[0-9]+$/)],
      ],
      reason: [reason, Validators.required],
      orderReference: [orderReference],
      freshdeskNote: [freshdeskNote],
    });
  }

  get valid(): boolean {
    return this.info.valid;
  }

  toFormControl(control: AbstractControl | null): FormControl {
    return control as FormControl;
  }
}
