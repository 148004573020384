import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-icon',
  template: `
    <span class="icon-wrapper" [ngClass]="combinedClasses">
      <span [innerHTML]="svgIcon"></span>
    </span>
  `,
  styleUrls: ['./custom-icon.component.scss'],
})
export class CustomIconComponent {
  private _name?: string;

  @Input() set name(value: string) {
    this._name = value;
    if (!value) {
      this.svgIcon = '';
      return;
    }
    this.setSvg();
  }

  get name(): string | undefined {
    return this._name;
  }

  @Input() classes: string = '';

  public svgIcon: string | SafeHtml = '';

  get combinedClasses(): string[] {
    return this.classes.split(' ').filter((cls) => cls.trim() !== '');
  }

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  setSvg() {
    this.httpClient
      .get(`assets/icons/${this.name}.svg`, { responseType: 'text' })
      .subscribe(
        (value: string) => {
          this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
        },
        (error) => {
          console.log(error);
          this.svgIcon = '';
        }
      );
  }
}
