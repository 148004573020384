// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Here OAuth tokens/JWTs are needed:
  allowedResourceServerUrls: [
    'http://localhost:8081', // SCSN service
    'http://localhost:8082', // Customer Service
    'http://localhost:8083', // Products Service
    'http://localhost:8084', // Orders Service
    'http://localhost:8085', // Invoices Service
    'http://localhost:8086', // Order History Service
  ],
  // External links
  baseOMSLink: 'https://indi-oms-eks.acc.indicloud.net/omt/app/',
  baseICMLink:
    'https://webshop-10.acc.indicloud.net/INTERSHOP/web/WFS/org-Site/nl_NL/webshop/EUR/',
  baseIndiUrl: 'https://webshop-10.acc.indicloud.net/',
  baseFreshdeskUrl: 'https://indi-omni.freshdesk.com',
  freshdeskServiceUrl: 'https://freshdesk-support-service.acc.indicloud.net/',
  orderHistoryServiceUrl: 'http://localhost:8086/',
  // SCSN
  scsnCustomersUrl: 'http://localhost:8081/customers',
  // Customers
  shippingAddressesUrl: 'http://localhost:8082/shipping-addresses',
  indiCustomersUrl: 'http://localhost:8082/indi-customers',
  connectionStatusCheckUrl: 'http://localhost:8082/q/health',
  // Products
  suppliersServiceUrl: 'http://localhost:8083/suppliers',
  specialsServiceUrl: 'http://localhost:8083/specials',
  priceCheckerServiceUrl: 'http://localhost:8083/prices',
  // Orders
  returnsServiceUrl: 'http://localhost:8084/returns',
  returnFailuresServiceUrl: 'http://localhost:8084/returns/faileds',
  dispatchFailuresServiceUrl: 'http://localhost:8084/dispatches/faileds',
  mancosServiceUrl: 'http://localhost:8084/mancos',
  salesordersServiceUrl: 'http://localhost:8084/verkooporders',
  sosEmailServiceUrl: 'http://localhost:8084/sos',
  // Invoices
  invoiceSearchServiceUrl: 'http://localhost:8085/invoices',
  paymentTermsCheckerServiceUrl: 'http://localhost:8085/payment-terms',
  // ATP
  atpServiceUrl: 'https://atp.acc.indicloud.net/atp',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
