<table class="table">
  <thead>
    <tr>
      <th scope="col" class="width-3 text-center">Kies</th>
      <th scope="col" class="width-5">Aantal</th>
      <th scope="col" class="width-10">SKU</th>
      <th scope="col" class="width-15">Productnaam uit PDF</th>
      <th scope="col" class="width-15">Orderregel referentie</th>
      <th scope="col" class="width-3">SKU link</th>
      <th scope="col" class="width-3">ATP</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let orderline of orderlines.controls; let i = index">
      <tr class="align-middle" [ngClass]="{ 'offset-rows-color': i % 2 === 0 }">
        <td class="text-center">
          <input
            type="checkbox"
            class="form-check-input"
            [formControl]="toFormControl(orderline.get('checked'))"
          />
        </td>
        <td>
          <input
            type="number"
            class="form-control form-control-sm no-arrows-in-number-input"
            placeholder="Aantal"
            [formControl]="toFormControl(orderline.get('quantity'))"
            [formValidity]="orderline.get('quantity')"
            [formValidityType]="'invalid'"
            [formValidityNeedsTouch]=false
          />
        </td>
        <td>
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="SKU"
            minlength="1"
            maxlength="20"
            autocomplete="off"
            [formControl]="toFormControl(orderline.get('sku'))"
            [formValidity]="orderline.get('sku')"
            [formValidityType]="'invalid'"
            [formValidityNeedsTouch]=false
          />
        </td>
        <td>{{ orderline.get("name")?.value }}</td>
        <td>
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Referentie"
            maxlength="255"
            autocomplete="off"
            [formControl]="toFormControl(orderline.get('orderlineRef'))"
          />
        </td>
        <td>
          <a
            [href]="
              baseIndiUrl + 'nl-nl/p/' + (orderline.get('sku')?.value || '')
            "
            target="_blank"
          >
            <button type="button" class="btn btn-outline-primary">
              <i class="fa-regular fa-link"></i>
            </button>
          </a>
        </td>
        <td>
          <app-atp-info
            [quantity]="orderline.get('quantity')?.value"
            [sku]="orderline.get('sku')?.value"
          ></app-atp-info>
        </td>
      </tr>
      <tr 
        [ngClass]="{ 'offset-rows-color': i % 2 === 0 }"
      >
        <td colspan="7" class="p-0">
          <ul class="text-danger my-0 mx-3">
            <li *ngIf="orderline.get('validationMessage')?.value" class="m-1">
              Fout bij PDF lezen:
              {{ orderline.get("validationMessage")?.value }}
            </li>
            <div
            *ngIf="orderline.get('checked')?.value"
            >
              <div
              *ngIf="
                  orderline.get('quantity')?.invalid &&
                  orderline.get('quantity')?.touched
                  "
              >
                <li
                  *ngIf="orderline.get('quantity')?.errors?.['required']"
                  class="m-1"
                  >
                  Aantal is verplicht en moet een getal zijn.
                </li>
                <li
                  *ngIf="orderline.get('quantity')?.errors?.['min']"
                  class="m-1"
                  >
                  Aantal moet een getal hoger dan 0.01 zijn.
                </li>
                <li *ngIf="orderline.get('quantity')?.errors?.['max']" class="m-1">
                  Aantal moet een getal lager dan 10.000 zijn.
                </li>
            <!--Commented out validation of quantity, because no min, max or step are provided-->
                <!-- <li
                  *ngIf="orderline.get('quantity')?.errors?.['invalidQuantity']"
                  class="m-1"
                >
                  Aantal moet tussen {{ orderline.value.minOrderQuantity }} en
                  {{ orderline.value.maxOrderQuantity }} liggen.
                </li>
                <li
                  *ngIf="orderline.get('quantity')?.errors?.['invalidQuantityForStepQuantity']"
                  class="m-1"
                >
                  Verkeerd aantal voor de stepQuantity van deze SKU.
                </li> -->
              </div>

              <li
                *ngIf="
                  orderline.get('sku')?.invalid && orderline.get('sku')?.touched
                "
                class="m-1"
              >
                SKU is verplicht.
              </li>
            </div>
          </ul>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<div *ngIf="!hasSelectedOrderlines" class="text-danger">
  Selecteer ten minste één geldige orderregel.
</div>
<div
  *ngIf="hasSelectedOrderlines && !selectedOrderlinesAreValid"
  class="text-danger"
>
  Niet alle geselecteerde orderregels zijn geldig.
</div>

<div class="d-flex mt-3">
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="addNewOrderline()"
  >
    <i class="fa-regular fa-plus fa-lg"></i> Orderregel toevoegen
  </button>
</div>
