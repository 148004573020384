<nav
  class="navbar navbar-expand-lg navbar-dark bg-primary"
  *ngIf="isAuthenticated()"
>
  <div class="container-fluid" *ngIf="isAuthenticated()">
    <a class="navbar-brand" href="#">Home</a>

    <div>
      <ul class="navbar-nav me-auto mb-lg-0 ms-3">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Specials
          </a>
          <ul class="dropdown-menu do-not-move">
            <li>
              <a
                class="dropdown-item"
                routerLink="/specials-kramp"
                routerLinkActive="active"
                >Kramp Specials
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                routerLink="/specials-festo"
                routerLinkActive="active"
                >Festo Specials
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                routerLink="/specials-tu"
                routerLinkActive="active"
                >TU Specials
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                routerLink="/specials-indi"
                routerLinkActive="active"
                >INDI Specials
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                routerLink="/leveranciers"
                routerLinkActive="active"
                >Leveranciers
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div>
      <ul class="navbar-nav ms-3">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Dispatches & Retouren
          </a>
          <ul class="dropdown-menu do-not-move">
            <li>
              <a
                class="dropdown-item"
                routerLink="/dispatches"
                routerLinkActive="active"
                >Dispatches
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                routerLink="/retouren"
                routerLinkActive="active"
                >Retouren
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                routerLink="/retour-uitval"
                routerLinkActive="active"
                >Retour uitval
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div>
      <ul class="navbar-nav ms-3">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            SOS-mails
          </a>
          <ul class="dropdown-menu do-not-move">
            <li>
              <a
                class="dropdown-item"
                routerLink="/sos-email-kramp"
                routerLinkActive="active"
                >SOS Email Kramp-ordernummers
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                routerLink="/sos-email-indi"
                routerLinkActive="active"
                >SOS Email INDI-ordernummers
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                routerLink="/sos-email-no-response"
                routerLinkActive="active"
                >SOS Email voor orders zonder response
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div>
      <ul class="navbar-nav ms-3">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Manco's
          </a>
          <ul class="dropdown-menu do-not-move">
            <li>
              <a
                class="dropdown-item"
                routerLink="/mancos/mancos-to-check-up-on"
                routerLinkActive="active"
                >Nog te checken Manco's
              </a>
              <a
                class="dropdown-item"
                routerLink="/mancos/new-manco"
                routerLinkActive="active"
                >Nieuwe manco
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div>
      <ul class="navbar-nav ms-3">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Verkooporders
          </a>
          <ul class="dropdown-menu do-not-move">
            <li>
              <a
                class="dropdown-item"
                routerLink="/verkooporders/verkooporder-overzicht"
                routerLinkActive="active"
                >Verkooporder overzicht
              </a>
              <a
                class="dropdown-item"
                routerLink="/verkooporders/nieuwe-verkooporder"
                routerLinkActive="active"
                >Nieuwe verkooporder
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="me-auto mb-lg-0 ms-3">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            De rest
          </a>
          <ul class="dropdown-menu do-not-move">
            <li>
              <a
                class="dropdown-item"
                routerLink="/factuur-zoeken"
                routerLinkActive="active"
                >Factuur zoeken
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                routerLink="/betaaltermijnen-checken"
                routerLinkActive="active"
                >Betaaltermijnen checken
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                routerLink="/price-checker"
                routerLinkActive="active"
                >Price checker
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                routerLink="/kramp-verzend-adressen"
                routerLinkActive="active"
                >Kramp verzend adressen
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                routerLink="/scsn-klanten"
                routerLinkActive="active"
                >SCSN Klanten
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="d-flex navbar-text me-3">Welkom, {{ getUserName() }}</div>
    <div class="d-flex">
      <a id="logout-btn" class="btn btn-outline-light" (click)="logout()"
        >Uitloggen</a
      >
    </div>
  </div>
</nav>

<div *ngIf="showConnectionError">
  <div class="d-flex justify-content-center">
    <app-connection-feedback></app-connection-feedback>
  </div>
</div>

<div class="p-3" *ngIf="!loginLoading; else loading">
  <router-outlet></router-outlet>
</div>

<ng-template #loading>
  <app-loading-gears></app-loading-gears>
</ng-template>
