import { Inject, Injectable } from '@angular/core';
import { ApiOrder } from '../api-order';
import { HttpClient } from '@angular/common/http';
import { SALESORDERS_SERVICE_URL } from 'src/app/injection-tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewSalesorderService {
  constructor(
    @Inject(SALESORDERS_SERVICE_URL) readonly salesordersServiceURL: string,
    readonly http: HttpClient
  ) {}

  getApiOrder(id: number): Observable<ApiOrder> {
    return this.http.get<ApiOrder>(
      this.salesordersServiceURL + '/' + id
    );
  }
}
