<h1 class="text-center border-bottom pb-3">Nieuwe verkooporder</h1>

<div *ngIf="isLoading">
  <app-loading-gears></app-loading-gears>
</div>

<div
      *ngIf="feedbackSettings.show"
    >
  <div class="feedback_box">
    <app-feedback
      [(settings)]="feedbackSettings"
    ></app-feedback>
  </div>
</div>

<div [ngClass]="{'d-none': isLoading || feedbackSettings.show}">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="needs-validation">
    <app-new-salesorder-orderlines
      [orderlines]="orderlines"
    ></app-new-salesorder-orderlines>

    <div class="d-flex mt-3">
      <div class="w-50">
        <app-new-salesorder-shipping-details
          [shippingDetails]="shippingDetails"
          [deliveryAddress]="apiOrder.orderDetails.deliveryAddress"
        ></app-new-salesorder-shipping-details>
      </div>

      <div class="w-50">
        <app-new-salesorder-invoice-details
          [invoiceDetails]="invoiceDetails"
        ></app-new-salesorder-invoice-details>

        <app-new-salesorder-info [info]="info"></app-new-salesorder-info>
      </div>
    </div>

    <div class="mt-1">
      <div>
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="
            !orderlinesComponent?.valid ||
            !shippingComponent?.valid ||
            !invoiceComponent?.valid ||
            !infoComponent?.valid
          "
        >
          <i class="fa-regular fa-shopping-cart fa-lg"></i> Verkooporder aanmaken
        </button>
      </div>
    </div>
  </form>
</div>