import { Component, Inject, Input } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BASE_INDI_URL } from 'src/app/injection-tokens';
import { EmptyOrderline, Orderline } from '../../order-details';

@Component({
  selector: 'app-new-salesorder-orderlines',
  templateUrl: './new-salesorder-orderlines.component.html',
  styleUrls: ['./new-salesorder-orderlines.component.scss'],
})
export class NewSalesorderOrderlinesComponent {
  readonly DEFAULT_MIN_ORDER_QUANTITY: number = 0.01;
  readonly DEFAULT_MAX_ORDER_QUANTITY: number = 10000;
  @Input() orderlines!: FormArray;

  constructor(
    @Inject(BASE_INDI_URL) readonly baseIndiUrl: string,
    private fb: FormBuilder
  ) {}

  createOrderlines(orderlines: Orderline[]): FormArray {
    const orderlineFormGroups = orderlines.map((orderline) =>
      this.createOrderlineGroup(orderline)
    );
    return this.fb.array(orderlineFormGroups);
  }

  createOrderlineGroup(orderline: Orderline): FormGroup {
    const formGroup = this.fb.group({
      checked: !!!orderline.validationMessage,
      sku: [orderline.item.sku, Validators.required],
      name: [orderline.item.name],
      quantity: [
        orderline.quantity,
        [Validators.required, Validators.min(0.01), Validators.max(10000)],
        // [
        //   Validators.required,
        //   Validators.min(
        //     orderline.minOrderQuantity || this.DEFAULT_MIN_ORDER_QUANTITY
        //   ),
        //   Validators.max(
        //     orderline.maxOrderQuantity || this.DEFAULT_MAX_ORDER_QUANTITY
        //   ),
        //   this.invalidQuantityValidator(
        //     orderline.minOrderQuantity,
        //     orderline.maxOrderQuantity
        //   ),
        //   this.invalidQuantityForStepQuantityValidator(orderline.stepQuantity),
        // ],
      ],
      minOrderQuantity: [
        orderline.minOrderQuantity || this.DEFAULT_MIN_ORDER_QUANTITY,
      ],
      maxOrderQuantity: [
        orderline.maxOrderQuantity || this.DEFAULT_MAX_ORDER_QUANTITY,
      ],
      orderlineRef: [orderline.orderlineRef],
      validationMessage: [orderline.validationMessage || ''],
    });

    return formGroup;
  }

  invalidQuantityValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value < min || value > max) {
        return { invalidQuantity: true };
      }
      return null;
    };
  }

  invalidQuantityForStepQuantityValidator(stepQuantity: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value % stepQuantity !== 0) {
        return { invalidQuantityForStepQuantity: true };
      }
      return null;
    };
  }

  addNewOrderline(): void {
    const newOrderlineFormGroup = this.createOrderlineGroup(EmptyOrderline());
    newOrderlineFormGroup.markAllAsTouched()

    this.orderlines.push(newOrderlineFormGroup);
  }

  get hasSelectedOrderlines(): boolean {
    return this.orderlines.value.some((o: any) => {
      return o.checked;
    });
  }

  get selectedOrderlinesAreValid(): boolean {
    const hasInvalidOrderline = this.orderlines.controls.some((o: any) => {
      return !!o.get('checked').value && !!!o.valid;
    });

    return !hasInvalidOrderline;
  }

  get valid(): boolean {
    return this.hasSelectedOrderlines && this.selectedOrderlinesAreValid;
  }

  toFormControl(control: AbstractControl | null): FormControl {
    return control as FormControl;
  }
}
