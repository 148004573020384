<div class="d-flex mt-4">
  <div class="w-75">
    <h2>Verkooporder informatie</h2>
    <div class="mb-2 w-100">
      <label class="form-label" for="freshdeskTicketNumber">
        <strong>Freshdesk ticketnummer</strong>
      </label>
      <input
        type="text"
        id="freshdeskTicketNumber"
        class="form-control form-control-sm"
        [formControl]="toFormControl(info.get('freshdeskTicketNumber'))"
        [formValidity]="info.get('freshdeskTicketNumber')!"
        [formValidityType]="'invalid'"
        [formValidityNeedsTouch]=false
      />
      <div
        *ngIf="
          info.get('freshdeskTicketNumber')?.invalid &&
          info.get('freshdeskTicketNumber')?.touched
        "
        class="text-danger"
      >
        Freshdesk ticketnummer is verplicht.
      </div>
    </div>
    <div class="mb-2 w-100">
      <label for="reason" class="form-label">
        <strong>Reden voor aanpassing</strong>
      </label>
      <select
        id="reason"
        class="form-select form-select-sm"
        [formControl]="toFormControl(info.get('reason'))"
        [formValidity]="info.get('reason')!"
        [formValidityType]="'invalid'"
        [formValidityNeedsTouch]=false
      >
        <option [value]="''">Selecteer een reden</option>
        <option [value]="'Reden 1'">Reden 1</option>
        <option [value]="'Reden 2'">Reden 2</option>
      </select>
      <div
        *ngIf="
          info.get('reason')?.hasError('required')
        "
        class="text-danger"
      >
        Reden is verplicht.
      </div>
      <div
        *ngIf="
          info.get('reason')?.hasError('invalidReason') &&
          info.get('reason')?.touched
        "
        class="text-danger"
      >
        Kies een geldige reden.
      </div>
    </div>
    <div class="mb-2 w-100">
      <label class="form-label" for="orderReference">
        <strong>Orderreferentie</strong>
      </label>
      <input
        type="text"
        id="orderReference"
        class="form-control form-control-sm"
        maxlength="36"
        [formControl]="toFormControl(info.get('orderReference'))"
      />
    </div>
    <div class="mb-2 w-100">
      <label class="form-label" for="freshdeskNote">
        <strong>Notitie</strong>
      </label>
      <textarea
        type="text"
        id="freshdeskNote"
        class="form-control form-control-sm"
        [formControl]="toFormControl(info.get('freshdeskNote'))"
        rows="3"
        maxlength="1024"
      ></textarea>
    </div>
  </div>
</div>
