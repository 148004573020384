<h1 class="text-center border-bottom pb-3">Nieuwe manco</h1>

<div class="d-flex">
  <div class="col col-sm-4">
    <app-new-manco-form
      (orderNumberEmitter)="orderNumber = $event"
      (freshdeskTicketNumberEmitter)="freshdeskTicketNumber = $event"
      (submitEmitter)="handleSearchSubmit()"
      (resetEmitter)="handleSearchReset()"
    ></app-new-manco-form>
  </div>
  <div class="col col-sm-1">&nbsp;</div>
  <div class="col col-sm-7">
    <app-feedback></app-feedback>
    <div *ngIf="isLoadingSearch || isLoadingResponse">
      <app-loading-gears></app-loading-gears>
    </div>
    <div *ngIf="orderDetails && !isLoadingSearch && !isLoadingResponse">
      <app-new-manco-order-info
        [orderDetails]="orderDetails"
        [freshdeskTicketNumber]="freshdeskTicketNumber"
      ></app-new-manco-order-info>
    </div>
  </div>
</div>
<div *ngIf="mancoOrderError">
  <app-manco-order-errors
    [error]="mancoOrderError"
    (closeError)="hideOrderError()"
  ></app-manco-order-errors>
</div>
<div>
  <div
    *ngIf="orderDetails && !isLoadingSearch"
    [ngClass]="{ 'd-none': isLoadingResponse }"
  >
    <app-new-manco-orderlines-table
      [orderDetails]="orderDetails"
      (submitMancoRequest)="handleMancoRequestSubmit($event)"
    ></app-new-manco-orderlines-table>
  </div>
  <div *ngIf="mancoOrderResponse">
    <app-manco-order-summary
      [response]="mancoOrderResponse"
      (closeSummary)="hideOrderSummary()"
    ></app-manco-order-summary>
  </div>
</div>
