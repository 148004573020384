<div>
  <div *ngIf="isAuthenticated(); else loginView">
    <div class="container-fluid">
      <div class="row g-5">
        <div class="col-2">
          <h5 class="px-3">Specials</h5>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item application-list-item"
              routerLink="/specials-kramp"
              routerLinkActive="active"
            >
              Kramp specials
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/specials-festo"
              routerLinkActive="active"
            >
              Festo specials
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/specials-tu"
              routerLinkActive="active"
            >
              TechnischeUnie specials
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/specials-indi"
              routerLinkActive="active"
            >
              INDI specials
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/leveranciers"
              routerLinkActive="active"
            >
              Leveranciers
            </li>
          </ul>
        </div>
        <div class="col-2">
          <h5 class="px-3">Dispatches & Retouren</h5>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item application-list-item"
              routerLink="/dispatches"
              routerLinkActive="active"
            >
              Dispatches
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/retouren"
              routerLinkActive="active"
            >
              Retouren
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/retour-uitval"
              routerLinkActive="active"
            >
              Retour uitval
            </li>
          </ul>
        </div>
        <div class="col-2">
          <h5 class="px-3">SOS-mails</h5>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item application-list-item"
              routerLink="/sos-email-kramp"
              routerLinkActive="active"
            >
              Kramp-ordernummers
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/sos-email-indi"
              routerLinkActive="active"
            >
              INDI-ordernummers
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/sos-email-no-response"
              routerLinkActive="active"
            >
              Orders zonder response
            </li>
          </ul>
        </div>
        <div class="col-2">
          <h5 class="px-3">Manco's</h5>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item application-list-item"
              routerLink="/mancos/mancos-to-check-up-on"
              routerLinkActive="active"
            >
              Nog te checken Manco's
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/mancos/new-manco"
              routerLinkActive="active"
            >
              Nieuwe manco
            </li>
          </ul>
        </div>
        <div class="col-2">
          <h5 class="px-3">Verkooporders</h5>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item application-list-item"
              routerLink="/verkooporders/verkooporder-overzicht"
              routerLinkActive="active"
            >
              Verkooporder overzicht
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/verkooporders/nieuwe-verkooporder"
              routerLinkActive="active"
            >
              Nieuwe verkooporder
            </li>
          </ul>
        </div>
        <div class="col-2">
          <h5 class="px-3">De rest</h5>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item application-list-item"
              routerLink="/factuur-zoeken"
              routerLinkActive="active"
            >
              Factuur zoeken
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/betaaltermijnen-checken"
              routerLinkActive="active"
            >
              Betaaltermijnen checken
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/price-checker"
              routerLinkActive="active"
            >
              Price checker
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/kramp-verzend-adressen"
              routerLinkActive="active"
            >
              Kramp verzend adressen
            </li>
            <li
              class="list-group-item application-list-item"
              routerLink="/scsn-klanten"
              routerLinkActive="active"
            >
              SCSN Klanten
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <ng-template #loginView>
    <div>
      <h1 class="text-center my-5">Welkom in het INDI Service Portal</h1>
      <div class="d-grid gap-2 col-4 mx-auto">
        <button type="button" (click)="login()" class="btn btn-outline-primary">
          klik hier om in te loggen
        </button>
      </div>
    </div>
  </ng-template>
</div>
