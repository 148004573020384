import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { BASE_INDI_URL } from 'src/app/injection-tokens';
import { OrderDetails, Orderline } from '../order-details';
import {
  MancoType,
  MancoTypeOptions,
  MancoOrderRequest,
  MancoOrderline,
} from '../manco-order-request';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  mancoTypeValidator,
  quantityRangeValidator,
  skuValidator,
} from './manco-request-validators';

@Component({
  selector: 'app-new-manco-orderlines-table',
  templateUrl: './new-manco-orderlines-table.component.html',
  styleUrls: ['./new-manco-orderlines-table.component.scss'],
})
export class NewMancoOrderlinesTableComponent {
  @Input() orderDetails!: OrderDetails;

  @Output('submitMancoRequest')
  submitMancoRequest: EventEmitter<MancoOrderRequest> = new EventEmitter();
  @ViewChildren('quantitySourceInput') inputElements?: QueryList<ElementRef>;

  mancoTypeOptions = MancoTypeOptions;
  form: FormGroup;
  selectedOrderlines: Map<number, FormGroup> = new Map();

  constructor(
    @Inject(BASE_INDI_URL) readonly baseIndiUrl: string,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      mancoType: [
        MancoType.Select,
        [Validators.required, mancoTypeValidator()],
      ],
      customerRef: [''],
      note: [''],
      deliveryAddress: this.fb.group({
        company: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        address: ['', Validators.required],
        postalCode: ['', Validators.required],
        city: ['', Validators.required],
        country: ['', [Validators.required, Validators.pattern(/^(NL|DE)$/)]],
        email: ['', [Validators.required, Validators.email]],
        telephoneNumber: ['', Validators.required],
        switchToDayShipping: [false],
      }),
      orderlines: this.fb.array([]),
      addedItems: this.fb.array([]),
      ishShippingMethodID: [''],
    });
  }

  ngOnChanges(): void {
    if (this.orderDetails) {
      this.populateFormWithOrderDetails();
    }
  }

  populateFormWithOrderDetails(): void {
    const deliveryAddress = this.orderDetails.deliveryAddress || {};

    this.form.patchValue({
      ishShippingMethodID: this.orderDetails.shippingMethod,
      mancoType: MancoType.Select,
      customerRef: this.orderDetails.reference,
      note: '',
      deliveryAddress: {
        company: this.orderDetails.customerDetails.companyName || '',
        firstName: deliveryAddress.firstName || '',
        lastName: deliveryAddress.lastName || '',
        address: deliveryAddress.address || '',
        postalCode: deliveryAddress.postalCode || '',
        city: deliveryAddress.city || '',
        country: deliveryAddress.country || '',
        email: deliveryAddress.emailAddress || '',
        telephoneNumber: deliveryAddress.telephoneNumber || '',
      },
    });
  }

  get addedItems(): FormArray<FormGroup> {
    return this.form.get('addedItems') as FormArray<FormGroup>;
  }

  getAddedItemFormGroup(index: number): FormGroup {
    return this.addedItems.at(index) as FormGroup;
  }

  addItem(): void {
    const newItemFormGroup = this.fb.group({
      selected: [true],
      sku: ['', [Validators.required, skuValidator()]],
      quantity: [null, [Validators.required, Validators.min(0.01)]],
      orderlineRef: [''],
    });

    this.addedItems.push(newItemFormGroup);

    setTimeout(() => {
      if (this.inputElements) {
        //these always exit when this method is called, the if is here for testing
        const inputToFocus =
          this.inputElements.toArray()[this.inputElements.length - 1];
        if (inputToFocus) {
          inputToFocus.nativeElement.focus();
        }
      }
    });
  }

  removeItem(index: number) {
    this.addedItems.removeAt(index);
  }

  onNewItemCheckboxChange(item: FormGroup, event: Event, index: number): void {
    const isSelected = (event.target as HTMLInputElement).checked;
    const skuControl = item.get('sku');
    const quantityControl = item.get('quantity');

    if (isSelected) {
      skuControl?.setValidators([Validators.required, skuValidator()]);
      quantityControl?.setValidators([
        Validators.required,
        Validators.min(0.01),
      ]);

      skuControl?.setValue('');
      quantityControl?.setValue('');

      setTimeout(() => {
        if (this.inputElements) {
          //these always exit when this method is called, the if is here for testing
          const inputToFocus =
            this.inputElements.toArray()[
              this.orderDetails.orderlines.length + index
            ];
          if (inputToFocus) {
            inputToFocus.nativeElement.focus();
          }
        }
      });
    } else {
      skuControl?.clearValidators();
      quantityControl?.clearValidators();

      skuControl?.markAsUntouched();
      quantityControl?.markAsUntouched();
    }
    skuControl?.updateValueAndValidity();
    quantityControl?.updateValueAndValidity();
  }

  isNightShippingMethod(): boolean {
    return this.orderDetails.shippingMethod
      .toLowerCase()
      .startsWith('shipping-nt');
  }

  onOrderlineCheckboxChange(
    orderline: Orderline,
    event: Event,
    index: number
  ): void {
    const checkbox = event.target as HTMLInputElement;

    if (checkbox.checked) {
      const newOrderline = this.fb.group({
        lineIDSource: [String(index + 1)],
        sku: [orderline.item.sku],
        nameSource: [orderline.item.description],
        quantitySource: [
          null,
          [
            Validators.required,
            quantityRangeValidator(
              orderline.quantityOrdered,
              orderline.quantityReturned
            ),
          ],
        ],
        quantityOrdered: [orderline.quantityOrdered],
        quantityReturned: [orderline.quantityReturned],
      });

      (this.form.get('orderlines') as FormArray).push(newOrderline);

      setTimeout(() => {
        if (this.inputElements) {
          //these always exit when this method is called, the if is here for testing
          const inputToFocus = this.inputElements.toArray()[index];
          if (inputToFocus) {
            inputToFocus.nativeElement.focus();
          }
        }
      });

      this.selectedOrderlines.set(index, newOrderline);
      newOrderline.get('quantitySource')?.enable();
    } else {
      const orderlinesArray = this.form.get('orderlines') as FormArray;
      const formGroupIndex = Array.from(this.selectedOrderlines.keys()).indexOf(
        index
      );

      if (formGroupIndex !== -1) {
        orderlinesArray.removeAt(formGroupIndex);
        this.selectedOrderlines.delete(index);
      }
    }
  }

  onQuantityChange(index: number, event: Event): void {
    const quantitySource = Number((event.target as HTMLInputElement).value);

    const orderlineGroup = this.selectedOrderlines.get(index);

    if (orderlineGroup) {
      const quantityControl = orderlineGroup.get('quantitySource');
      quantityControl?.setValue(quantitySource);
      quantityControl?.markAsTouched();
      quantityControl?.updateValueAndValidity();
    }
  }

  onSwitchToDayShippingChange(): void {
    const isChecked = this.form.get(
      'deliveryAddress.switchToDayShipping'
    )?.value;

    this.form.patchValue({
      ishShippingMethodID: isChecked
        ? 'shipping-standard-free'
        : this.orderDetails.shippingMethod,
    });
  }

  getSelectedItems(): FormGroup[] {
    return this.addedItems.controls.filter(
      (group) => group.get('selected')?.value
    ) as FormGroup[];
  }

  combineSelectedOrderlinesAndAddedItems(): MancoOrderline[] {
    const selectedOrderlineValues: MancoOrderline[] = Array.from(
      this.selectedOrderlines.values()
    ).map((formGroup, index) => {
      const rawValue = formGroup.getRawValue();

      const { quantityOrdered, quantityReturned, ...sanitizedOrderline } =
        rawValue;

      return {
        ...sanitizedOrderline,
        rowNumber: index + 1,
        properties: { source: 'original-order' },
      };
    });

    this.getSelectedItems().forEach((item, index) => {
      const newMancoOrderLine: MancoOrderline = {
        rowNumber: selectedOrderlineValues.length + index + 1,
        sku: item.get('sku')!.getRawValue(),
        quantitySource: item.get('quantity')!.getRawValue(),
        orderlineRef: item.get('orderlineRef')
          ? item.get('orderlineRef')?.getRawValue()
          : undefined,
        properties: { source: 'manually-added' },
      };
      selectedOrderlineValues.push(newMancoOrderLine);
    });

    return selectedOrderlineValues;
  }

  onSubmit(): void {
    const orderlines = this.combineSelectedOrderlinesAndAddedItems();

    const { switchToDayShipping, ...sanitizedDeliveryAddress } =
      this.form.value.deliveryAddress;

    const { addedItems, ...formValueWithoutNewItems } = this.form.value;

    const newMancoRequest: MancoOrderRequest = {
      ...formValueWithoutNewItems,
      forCustomerNumber: this.orderDetails.customerDetails.customerNo,
      forOrderNumber: this.orderDetails.orderNumber,
      customerRef: this.orderDetails.reference,
      deliveryAddress: {
        ...sanitizedDeliveryAddress,
      },
      orderlines: orderlines,
    };

    this.submitMancoRequest.emit(newMancoRequest);
  }

  cannotReorder(orderline: Orderline): boolean {
    return (
      orderline.deliveryStatus.quantityInProcess === 0 &&
      orderline.quantityOrdered ===
        orderline.quantityCancelled + orderline.quantityReturned
    );
  }

  invalidOrderlinesAndItems(): boolean {
    const orderlinesArray = this.form.get('orderlines') as FormArray;
    const addedItemsArray = this.addedItems;

    const hasInvalidOrderlines = orderlinesArray.controls.some(
      (orderline) => !orderline.valid
    );

    const hasInvalidAddedItems = addedItemsArray.controls.some((addedItem) => {
      const skuControl = addedItem.get('sku');
      const quantityControl = addedItem.get('quantity');
      const isSelected = addedItem.get('selected')?.value;

      return isSelected && (!skuControl?.valid || !quantityControl?.valid);
    });

    return (
      (orderlinesArray.length === 0 &&
        !addedItemsArray.controls.some(
          (item) => item.get('selected')?.value
        )) ||
      hasInvalidOrderlines ||
      hasInvalidAddedItems
    );
  }

  resetForm() {
    const orderlinesArray = this.form.get('orderlines') as FormArray;
    while (orderlinesArray.length > 0) {
      orderlinesArray.removeAt(0);
    }

    const newItemsArray = this.form.get('addedItems') as FormArray;
    while (newItemsArray.length > 0) {
      newItemsArray.removeAt(0);
    }

    this.selectedOrderlines.clear();

    this.form.reset();
  }
}
