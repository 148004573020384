<div *ngIf="shippingDetails">
  <div class="mb-2 w-75">
    <label class="form-label" for="shippingMethod">
      <strong>Verzendmethode</strong>
    </label>
    <select
      *ngIf="shippingDetails"
      id="shippingMethod"
      class="form-select form-select-sm"
      [formControl]="toFormControl(shippingDetails.get('shippingMethod'))"
      [formValidity]="shippingDetails.get('shippingMethod')"
      [formValidityType]="'invalid'"
      [formValidityNeedsTouch]=false
    >
      <option value="">Selecteer een verzendmethode</option>
      <ng-container *ngFor="let method of AVAILABLE_SHIPPING_METHODS">
        <option [value]="method">{{ method }}</option>
      </ng-container>
    </select>
    <div
      *ngIf="shippingDetails.get('shippingMethod')?.invalid"
      class="text-danger"
    >
      <div *ngIf="shippingDetails.get('shippingMethod')?.errors?.['required']">
        Verzendmethode is verplicht.
      </div>
      <div
        *ngIf="shippingDetails.get('shippingMethod')?.errors?.['invalidShippingMethod']"
      >
        Selecteer een geldige verzendmethode.
      </div>
    </div>
    <div
      *ngIf="shippingDetails.get('shippingMethod')?.value == ''"
      class="text-danger"
    >
      Selecteer een verzendmethode.
    </div>
  </div>

  <!-- Shipping Address (Nightshipping) -->
  <div
    *ngIf="shippingDetails.get('shippingMethod')?.value === 'Nachtbezorging'"
  >
    <h2>Verzendadres</h2>
    {{ shippingDetails.get("deliveryAddress.company")?.value }}
    <br />
    {{ shippingDetails.get("deliveryAddress.firstName")?.value }}
    {{ shippingDetails.get("deliveryAddress.lastName")?.value }}
    <br />
    {{ shippingDetails.get("deliveryAddress.address")?.value }}
    <br />
    {{ shippingDetails.get("deliveryAddress.postalCode")?.value }}
    {{ shippingDetails.get("deliveryAddress.city")?.value }}
    <br />
    <span *ngIf="shippingDetails.get('deliveryAddress.country')?.value == 'NL'">
      Nederland (NL)
    </span>
    <span *ngIf="shippingDetails.get('deliveryAddress.country')?.value == 'DE'">
      Duitsland (DE)
    </span>
    <br />
  </div>

  <!-- Shipping Address (Dayshipping) -->
  <div *ngIf="shippingDetails.get('shippingMethod')?.value === 'Dagbezorging'">
    <h2>Verzendadres</h2>
    <div class="justify-content-center align-items-start w-75">
      <div class="mb-2 w-100">
        <label class="form-label" for="company">
          <strong>Bedrijfsnaam</strong>
        </label>
        <input
          type="text"
          id="company"
          class="form-control form-control-sm"
          [formControl]="toFormControl(shippingDetails.get('deliveryAddress.company'))"
          [formValidity]="shippingDetails.get('deliveryAddress.company')!"
          [formValidityType]="'invalid'"
          [formValidityNeedsTouch]=false
        />
        <div
          *ngIf="shippingDetails.get('deliveryAddress.company')?.invalid"
          class="text-danger"
        >
          Bedrijfsnaam is verplicht.
        </div>
      </div>
      <div class="d-flex flex-wrap mb-2 w-100 gap-2">
        <div class="flex-grow-1">
          <label for="firstName" class="form-label">
            <strong>Voornaam</strong>
          </label>
          <input
            type="text"
            id="firstName"
            class="form-control form-control-sm"
            [formControl]="toFormControl(shippingDetails.get('deliveryAddress.firstName'))"
            [formValidity]="shippingDetails.get('deliveryAddress.firstName')!"
            [formValidityType]="'invalid'"
            [formValidityNeedsTouch]=false
          />
          <div
            *ngIf="shippingDetails.get('deliveryAddress.firstName')?.invalid"
            class="text-danger"
          >
            Voornaam is verplicht.
          </div>
        </div>
        <div class="flex-grow-1">
          <label for="lastName" class="form-label">
            <strong>Achternaam</strong>
          </label>
          <input
            type="text"
            id="lastName"
            class="form-control form-control-sm"
            [formControl]="toFormControl(shippingDetails.get('deliveryAddress.lastName'))"
            [formValidity]="shippingDetails.get('deliveryAddress.lastName')!"
            [formValidityType]="'invalid'"
            [formValidityNeedsTouch]=false
          />
          <div
            *ngIf="shippingDetails.get('deliveryAddress.lastName')?.invalid"
            class="text-danger"
          >
            Achternaam is verplicht.
          </div>
        </div>
      </div>
      <div class="mb-2 w-100">
        <label class="form-label" for="address">
          <strong>Adres</strong>
        </label>
        <input
          type="text"
          id="address"
          class="form-control form-control-sm"
          [formControl]="toFormControl(shippingDetails.get('deliveryAddress.address'))"
          [formValidity]="shippingDetails.get('deliveryAddress.address')!"
          [formValidityType]="'invalid'"
          [formValidityNeedsTouch]=false
        />
        <div
          *ngIf="shippingDetails.get('deliveryAddress.address')?.invalid"
          class="text-danger"
        >
          Adres is verplicht.
        </div>
      </div>
      <div class="mb-2 w-100">
        <label class="form-label" for="postalCode">
          <strong>Postcode</strong>
        </label>
        <input
          type="text"
          id="postalCode"
          class="form-control form-control-sm"
          [formControl]="toFormControl(shippingDetails.get('deliveryAddress.postalCode'))"
          [formValidity]="shippingDetails.get('deliveryAddress.postalCode')!"
          [formValidityType]="'invalid'"
          [formValidityNeedsTouch]=false
        />
        <div
          *ngIf="shippingDetails.get('deliveryAddress.postalCode')?.invalid"
          class="text-danger"
        >
          Postcode is verplicht.
        </div>
      </div>
      <div class="mb-2 w-100">
        <label class="form-label" for="city">
          <strong>Plaats</strong>
        </label>
        <input
          type="text"
          id="city"
          class="form-control form-control-sm"
          [formControl]="toFormControl(shippingDetails.get('deliveryAddress.city'))"
          [formValidity]="shippingDetails.get('deliveryAddress.city')!"
          [formValidityType]="'invalid'"
          [formValidityNeedsTouch]=false
        />
        <div
          *ngIf="
            shippingDetails.get('deliveryAddress.city')?.invalid
          "
          class="text-danger"
        >
          Plaats is verplicht.
        </div>
      </div>
      <div class="mb-2 w-100">
        <label for="country" class="form-label">
          <strong>Land</strong>
        </label>
        <select
          id="country"
          class="form-select form-select-sm"
          [formControl]="
            toFormControl(shippingDetails.get('deliveryAddress.country'))
          "
          [formValidity]="shippingDetails.get('deliveryAddress.country')!"
          [formValidityType]="'invalid'"
          [formValidityNeedsTouch]=false
        >
          <option [value]="''">Selecteer een land</option>
          <option [value]="'NL'">Nederland (NL)</option>
          <option [value]="'DE'">Duitsland (DE)</option>
        </select>
        <div
          *ngIf="
            shippingDetails.get('deliveryAddress.country')?.hasError('required')
          "
          class="text-danger"
        >
          Land is verplicht.
        </div>
        <div
          *ngIf="
            shippingDetails.get('deliveryAddress.country')?.hasError('invalidCountry')
          "
          class="text-danger"
        >
          Land moet Nederland (NL) of Duitsland (DE) zijn.
        </div>
      </div>
    </div>
  </div>
</div>
