import { EmptyOrderDetails, OrderDetails } from './order-details';

export interface ApiOrder {
  id: number;
  ticketId: number;
  errorText?: string;
  orderDetails: OrderDetails;
  reason: string;
  freshdeskNote?: string;
}

export const EmptyApiOrder = (): ApiOrder => ({
  id: 88,
  ticketId: 1231298,
  orderDetails: EmptyOrderDetails(),
  reason: '',
});
