import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Address } from '../../order-details';

@Component({
  selector: 'app-new-salesorder-shipping-details',
  templateUrl: './new-salesorder-shipping-details.component.html',
  styleUrls: ['./new-salesorder-shipping-details.component.scss'],
})
export class NewSalesorderShippingDetailsComponent
  implements OnInit, OnChanges
{
  readonly AVAILABLE_SHIPPING_METHODS: string[] = [
    'Dagbezorging',
    'Nachtbezorging',
    'Afhalen bij balie',
  ];
  readonly COUNTRY_PATTERN: RegExp = /^(NL|DE)$/;

  @Input() shippingDetails!: FormGroup;
  @Input() deliveryAddress!: Address;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    // Initializing this as empty to prevent errors
    if (!this.shippingDetails.get('shippingMethod')) {
      this.shippingDetails = this.fb.group({
        shippingMethod: ['', Validators.required],
      });
    }
  }

  ngOnChanges(): void {
    this.handleShippingMethodChanges();
  }

  handleShippingMethodChanges(): void {
    this.shippingDetails
      .get('shippingMethod')
      ?.valueChanges.subscribe((value: string) => {
        if (value !== 'Dagbezorging') {
          this.setDeliveryAddressValues(this.deliveryAddress);
        }
      });
  }

  setDeliveryAddressValues(deliveryAddress: Address): void {
    this.shippingDetails.get('deliveryAddress')?.patchValue({
      company: deliveryAddress.company,
      firstName: deliveryAddress.firstName,
      lastName: deliveryAddress.lastName,
      address: deliveryAddress.address,
      postalCode: deliveryAddress.postalCode,
      city: deliveryAddress.city,
      country: deliveryAddress.country,
    });
  }

  createShippingDetails(
    shippingMethod: string,
    deliveryAddress: Address
  ): FormGroup {
    return this.fb.group({
      shippingMethod: [
        shippingMethod,
        [
          Validators.required,
          this.validShippingMethodValidator(this.AVAILABLE_SHIPPING_METHODS),
        ],
      ],
      deliveryAddress: this.createAddressGroup(deliveryAddress),
    });
  }

  createAddressGroup(deliveryAddress: Address): FormGroup {
    return this.fb.group({
      company: [deliveryAddress.company, Validators.required],
      firstName: [deliveryAddress.firstName, Validators.required],
      lastName: [deliveryAddress.lastName, Validators.required],
      address: [deliveryAddress.address, Validators.required],
      postalCode: [deliveryAddress.postalCode, Validators.required],
      city: [deliveryAddress.city, Validators.required],
      country: [
        deliveryAddress.country,
        [Validators.required, Validators.pattern(this.COUNTRY_PATTERN)],
      ],
    });
  }

  validShippingMethodValidator(validOptions: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value || validOptions.includes(control.value)) {
        return null;
      }
      return { invalidShippingMethod: true };
    };
  }

  toFormControl(control: AbstractControl | null): FormControl {
    return control as FormControl;
  }

  get valid(): boolean {
    return this.shippingDetails.valid;
  }
}
