import { Directive, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[formValidity]',
})
export class FormValidityDirective {
  @Input('formValidity') control!: AbstractControl<any, any> | null;
  @Input('formValidityType') type: 'valid' | 'invalid' | 'both' = 'both';
  @Input('formValidityNeedsTouch') needsTouch: boolean = true;

  @HostBinding('class.is-valid') get isValid(): boolean {
    if (!this.control) {
      return false;
    }

    if (this.type === 'valid' || this.type === 'both') {
      return this.control.valid;
    }

    return false;
  }

  @HostBinding('class.is-invalid') get isInvalid(): boolean {
    if (!this.control) {
      return false;
    }

    if (this.type === 'invalid' || this.type === 'both') {
      return this.control.invalid && !this.needsTouch;
    }

    return false;
  }
}
