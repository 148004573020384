import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { EmptyApiOrder, ApiOrder } from '../api-order';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BASE_INDI_URL } from 'src/app/injection-tokens';
import { NewSalesorderService } from './new-salesorder.service';
import { NewSalesorderOrderlinesComponent } from './new-salesorder-orderlines/new-salesorder-orderlines.component';
import { NewSalesorderShippingDetailsComponent } from './new-salesorder-shipping-details/new-salesorder-shipping-details.component';
import { NewSalesorderInvoiceDetailsComponent } from './new-salesorder-invoice-details/new-salesorder-invoice-details.component';
import { NewSalesorderInfoComponent } from './new-salesorder-info/new-salesorder-info.component';
import { ActivatedRoute } from '@angular/router';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';

@Component({
  selector: 'app-new-salesorder',
  templateUrl: './new-salesorder.component.html',
  styleUrls: ['./new-salesorder.component.scss'],
})
export class NewSalesorderComponent implements OnInit, AfterViewInit {
  apiOrder: ApiOrder = EmptyApiOrder();
  form!: FormGroup;
  id: number = 0;
  isLoading: boolean = true;

  @ViewChild(NewSalesorderOrderlinesComponent)
  orderlinesComponent?: NewSalesorderOrderlinesComponent;
  @ViewChild(NewSalesorderShippingDetailsComponent)
  shippingComponent?: NewSalesorderShippingDetailsComponent;
  @ViewChild(NewSalesorderInvoiceDetailsComponent)
  invoiceComponent?: NewSalesorderInvoiceDetailsComponent;
  @ViewChild(NewSalesorderInfoComponent)
  infoComponent?: NewSalesorderInfoComponent;

  feedbackSettings: FeedbackSettings = {
      identifier: 'generalFeedback',
      show: false,
      type: FeedbackType.error,
      message: '',
      buttonText: 'Terug naar hoofdmenu',
      closeButton: false,
    };

  constructor(
    @Inject(BASE_INDI_URL) readonly baseIndiUrl: string,
    private route: ActivatedRoute,
    private service: NewSalesorderService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.queryParamMap.get('id') || '0');
    this.form = this.createFormGroup();
  }

  ngAfterViewInit(): void {
    if (Number.isNaN(this.id) || this.id === 0) {
      this.feedbackSettings.message = 'Geen geldige id gegeven';
      this.feedbackSettings.show = true;
      this.isLoading = false;
    } else {
      this.service.getApiOrder(this.id).subscribe(
        (apiOrder: ApiOrder) => {
          this.apiOrder = apiOrder;
          this.initializeForm();
          this.isLoading = false;
        },
        (error: any) => {
          console.error('Failed to get order', error);
          this.feedbackSettings.message = 'Er is iets fout gegaan bij het ophalen van de verkooporder';
          this.feedbackSettings.show = true;
          this.isLoading = false;
        }
      );
    }
  }

  createFormGroup(): FormGroup {
    return this.fb.group({
      orderlines: this.fb.array([]),
      shippingDetails: this.fb.group({}),
      invoiceDetails: this.fb.group({}),
      info: this.fb.group({}),
    });
  }

  initializeForm(): void {
    this.setOrderlines();
    this.setShippingDetails();
    this.setInvoiceDetails();
    this.setInfo();
  }

  setOrderlines(): void {
    this.form.setControl(
      'orderlines',
      this.orderlinesComponent!.createOrderlines(
        this.apiOrder.orderDetails.orderlines
      )
    );
  }

  setShippingDetails(): void {
    this.form.setControl(
      'shippingDetails',
      this.shippingComponent!.createShippingDetails(
        this.apiOrder.orderDetails.shippingMethod,
        this.apiOrder.orderDetails.deliveryAddress
      )
    );
  }

  setInvoiceDetails(): void {
    this.form.setControl(
      'invoiceDetails',
      this.invoiceComponent!.createInvoiceDetails(
        this.apiOrder.orderDetails.customer.invoiceEmailAddress,
        this.apiOrder.orderDetails.customer.invoiceAddress
      )
    );
  }

  setInfo(): void {
    this.form.setControl(
      'info',
      this.infoComponent!.createInfo(
        this.apiOrder.ticketId,
        this.apiOrder.reason,
        this.apiOrder.orderDetails.customerRef || '',
        this.apiOrder.freshdeskNote || ''
      )
    );
  }

  get orderlines(): FormArray {
    return this.form.get('orderlines') as FormArray;
  }

  get shippingDetails(): FormGroup {
    return this.form.get('shippingDetails') as FormGroup;
  }

  get invoiceDetails(): FormGroup {
    return this.form.get('invoiceDetails') as FormGroup;
  }

  get info(): FormGroup {
    return this.form.get('info') as FormGroup;
  }

  onSubmit(): void {
    console.log('Updated form values:', this.form.getRawValue());
  }
}
