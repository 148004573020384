<form [formGroup]="form" (ngSubmit)="onSubmit()" class="needs-validation">
  <!-- Orderlines Table -->
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" class="width-3 text-center">Selecteer</th>
        <th scope="col" class="width-5">Aantal</th>
        <th scope="col" class="width-15">SKU</th>
        <th scope="col" class="width-20">Productnaam</th>
        <th scope="col" class="width-5">Besteld</th>
        <th scope="col" class="width-10">Orderregelstatus</th>
        <th scope="col" class="width-10">ATP</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let orderline of orderDetails.orderlines; let i = index">
        <td class="text-center">
          <input
            type="checkbox"
            class="form-check-input"
            [checked]="selectedOrderlines.has(i)"
            (change)="onOrderlineCheckboxChange(orderline, $event, i)"
            [disabled]="cannotReorder(orderline)"
          />
        </td>
        <td>
          <input
            #quantitySourceInput
            id="quantitySourceInput+ {{ i }}"
            type="number"
            class="form-control form-control-sm"
            (input)="onQuantityChange(i, $event)"
            placeholder="Aantal"
            [disabled]="!selectedOrderlines.has(i) || cannotReorder(orderline)"
            [ngClass]="{
              'is-invalid':
                selectedOrderlines.get(i)?.get('quantitySource')?.invalid &&
                selectedOrderlines.get(i)?.get('quantitySource')?.touched,
              'is-valid':
                selectedOrderlines.get(i)?.get('quantitySource')?.valid &&
                selectedOrderlines.get(i)?.get('quantitySource')?.touched
            }"
          />
          <div
            *ngIf="
              selectedOrderlines.get(i)?.get('quantitySource')?.invalid &&
              selectedOrderlines.get(i)?.get('quantitySource')?.touched
            "
            class="text-danger"
          >
            <div
              *ngIf="selectedOrderlines.get(i)?.get('quantitySource')?.errors?.['required']"
            >
              Aantal is verplicht.
            </div>
            <div
              *ngIf="selectedOrderlines.get(i)?.get('quantitySource')?.errors?.['invalidQuantity']"
            >
              Aantal moet tussen 1 en het maximale aantal geleverde stuks
              liggen.
            </div>
          </div>
        </td>

        <td>
          <a
            class="c-block-media__url"
            href="{{ baseIndiUrl }}nl-nl/p/{{ orderline.item.sku }}"
            target="_blank"
            >{{ orderline.item.sku }}
          </a>
        </td>
        <td>
          <a
            class="c-block-media__url"
            href="{{ baseIndiUrl }}nl-nl/p/{{ orderline.item.sku }}"
            target="_blank"
            >{{ orderline.item.description }}
          </a>
        </td>
        <td>{{ orderline.quantityOrdered }} {{ orderline.item.units }}</td>
        <td>
          <app-delivery-status-column
            [orderline]="orderline"
          ></app-delivery-status-column>
        </td>
        <td>
          <app-atp-info
            [quantity]="
              selectedOrderlines.get(i)?.get('quantitySource')?.value ??
              (orderline.quantityOrdered || 0) -
                (orderline.quantityCancelled || 0) +
                (orderline.quantityReturned || 0)
            "
            [sku]="orderline.item.sku"
            [units]="orderline.item.units"
          ></app-atp-info>
        </td>
      </tr>
      <!-- added items -->

      <tr *ngIf="addedItems.length > 0">
        <th scope="col" class="width-3 text-center">Selecteer</th>
        <th scope="col" class="width-5">Aantal</th>
        <th scope="col" class="width-15">SKU</th>
        <th scope="col" class="width-20">orderline referentie</th>
        <th scope="col" class="width-5">verwijderen</th>
        <th scope="col" class="width-10"></th>
        <th scope="col" class="width-10">ATP</th>
      </tr>

      <tr
        *ngFor="let addedItem of addedItems.controls; let i = index"
        [formGroup]="getAddedItemFormGroup(i)"
      >
        <td class="text-center">
          <input
            type="checkbox"
            class="form-check-input"
            (change)="onNewItemCheckboxChange(addedItem, $event, i)"
            formControlName="selected"
          />
        </td>
        <td>
          <input
            #quantitySourceInput
            type="number"
            class="form-control form-control-sm"
            formControlName="quantity"
            placeholder="Aantal"
            [ngClass]="{
              'is-invalid':
                addedItem.get('quantity')?.invalid &&
                addedItem.get('quantity')?.touched,
              'is-valid':
                addedItem.get('quantity')?.valid &&
                addedItem.get('quantity')?.touched
            }"
          />
          <div
            class="text-danger"
            *ngIf="
              addedItem.get('quantity')?.touched &&
              addedItem.get('quantity')?.invalid
            "
          >
            <div *ngIf="addedItem.get('quantity')?.hasError('required')">
              Aantal is verplicht.
            </div>
            <div *ngIf="addedItem.get('quantity')?.hasError('min')">
              Aantal moet groter zijn dan 0.
            </div>
          </div>
        </td>
        <td>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="sku"
            placeholder="SKU"
            [ngClass]="{
              'is-invalid':
                addedItem.get('sku')?.invalid && addedItem.get('sku')?.touched,
              'is-valid':
                addedItem.get('sku')?.valid && addedItem.get('sku')?.touched
            }"
          />
          <div
            class="text-danger"
            *ngIf="
              addedItem.get('sku')?.touched && addedItem.get('sku')?.invalid
            "
          >
            <div *ngIf="addedItem.get('sku')?.hasError('required')">
              SKU is verplicht.
            </div>
            <div *ngIf="addedItem.get('sku')?.hasError('invalidLength')">
              SKU is minimaal 1 en maximaal 30 karakters.
            </div>
            <div *ngIf="addedItem.get('sku')?.hasError('invalidFormat')">
              SKU mag alleen uit nummers en (hoofd)letters bestaan.
              (Uitzonderingen: Koerier, XKosten)
            </div>
          </div>
        </td>

        <td>
          <input
            type="text"
            class="form-control form-control-sm"
            formControlName="orderlineRef"
            placeholder="Orderline referentie"
            [ngClass]="{
              'is-invalid':
                addedItem.get('orderlineRef')?.invalid &&
                addedItem.get('orderlineRef')?.touched,
              'is-valid':
                addedItem.get('orderlineRef')?.valid &&
                addedItem.get('orderlineRef')?.touched
            }"
          />
          <div
            class="text-danger"
            *ngIf="
              addedItem.get('orderlineRef')?.touched &&
              addedItem.get('orderlineRef')?.invalid
            "
          >
            <div *ngIf="addedItem.get('orderlineRef')?.hasError('required')">
              SKU is verplicht.
            </div>
            <div
              *ngIf="addedItem.get('orderlineRef')?.hasError('invalidLength')"
            >
              SKU is minimaal 1 en maximaal 30 karakters.
            </div>
            <div
              *ngIf="addedItem.get('orderlineRef')?.hasError('invalidFormat')"
            >
              SKU mag alleen uit nummers en (hoofd)letters bestaan.
              (Uitzonderingen: Koerier, XKosten)
            </div>
          </div>
        </td>
        <td>
          <div class="btn btn-primary" (click)="removeItem(i)">
            <i class="fa-solid fa-trash"></i>
          </div>
        </td>
        <td></td>
        <td>
          <app-atp-info
            [quantity]="
              addedItem.get('quantity')
                ? addedItem.get('quantity')!.value
                : null
            "
            [sku]="addedItem.get('sku') ? addedItem.get('sku')!.value : null"
          ></app-atp-info>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="btn btn-primary" (click)="addItem()">
    <i class="fa-solid fa-cart-plus"></i> Artikel toevoegen
  </div>
  <div *ngIf="invalidOrderlinesAndItems()" class="text-danger">
    Selecteer ten minste één geldige orderregel.
  </div>
  <!-- Rest of the form -->
  <div class="d-flex justify-content-between">
    <!-- Other Input Section -->
    <div class="d-flex flex-column w-50">
      <h3 class="text-center mb-3">Manco order gegevens</h3>
      <label class="form-label" for="mancoTypeSelect"
        ><strong>Reden opnieuw bestellen:</strong></label
      >
      <select
        class="form-select"
        [ngClass]="
          form.get('mancoType')?.touched
            ? form.get('mancoType')?.valid
              ? 'is-valid'
              : 'is-invalid'
            : ''
        "
        id="mancoTypeSelect"
        formControlName="mancoType"
      >
        <option *ngFor="let option of mancoTypeOptions" [value]="option.value">
          {{ option.label }}
        </option>
      </select>

      <div
        *ngIf="form.get('mancoType')?.invalid && form.get('mancoType')?.touched"
        class="invalid-feedback"
      >
        Kies een geldige reden.
      </div>
      <div class="d-flex flex-column mt-4">
        <label class="form-label" for="customerRef"
          ><strong>Orderreferentie:</strong></label
        >
        <input
          id="customerRef"
          type="text"
          class="form-control form-control-sm"
          formControlName="customerRef"
          [ngClass]="{
            'is-invalid': form.get('customerRef')?.invalid,
            'is-valid': form.get('customerRef')?.valid
          }"
        />
        <div *ngIf="form.get('customerRef')?.invalid" class="text-danger">
          Klopt niet
        </div>
      </div>
      <div class="d-flex flex-column mt-4">
        <strong>Notitie</strong>
        <textarea
          class="form-control"
          placeholder="Notitie aan/voor de Check-upper voor de follow-up"
          formControlName="note"
          rows="8"
          maxlength="255"
        ></textarea>
      </div>
    </div>
    <!-- Delivery Address Section -->
    <div class="d-flex flex-column align-items-center w-50">
      <h3 class="text-center mb-3">Adresgegevens</h3>
      <div
        class="d-flex flex-column justify-content-center align-items-start w-75"
        formGroupName="deliveryAddress"
      >
        <div class="d-flex flex-column mb-2 w-100">
          <label class="form-label" for="company"
            ><strong>Bedrijfsnaam</strong></label
          >
          <input
            id="company"
            type="text"
            class="form-control form-control-sm"
            formControlName="company"
            [ngClass]="{
              'is-invalid': form.get('deliveryAddress.company')?.invalid,
              'is-valid': form.get('deliveryAddress.company')?.valid
            }"
          />
          <div
            *ngIf="form.get('deliveryAddress.company')?.invalid"
            class="text-danger"
          >
            Bedrijfsnaam is verplicht.
          </div>
        </div>
        <div class="d-flex flex-wrap mb-2 w-100 gap-3">
          <div class="flex-grow-1">
            <label for="firstName" class="form-label">
              <strong>Voornaam</strong>
            </label>
            <input
              id="firstName"
              type="text"
              class="form-control form-control-sm"
              formControlName="firstName"
              [ngClass]="{
                'is-invalid': form.get('deliveryAddress.firstName')?.invalid,
                'is-valid': form.get('deliveryAddress.firstName')?.valid
              }"
            />
            <div
              *ngIf="form.get('deliveryAddress.firstName')?.invalid"
              class="text-danger"
            >
              Voornaam is verplicht.
            </div>
          </div>
          <div class="flex-grow-1">
            <label for="lastName" class="form-label">
              <strong>Achternaam</strong>
            </label>
            <input
              id="lastName"
              type="text"
              class="form-control form-control-sm"
              formControlName="lastName"
              [ngClass]="{
                'is-invalid': form.get('deliveryAddress.lastName')?.invalid,
                'is-valid': form.get('deliveryAddress.lastName')?.valid
              }"
            />
            <div
              *ngIf="form.get('deliveryAddress.lastName')?.invalid"
              class="text-danger"
            >
              Achternaam is verplicht.
            </div>
          </div>
        </div>
        <div class="d-flex flex-column mb-2 w-100">
          <label class="form-label" for="address"><strong>Adres</strong></label>
          <input
            id="address"
            type="text"
            class="form-control form-control-sm"
            formControlName="address"
            [ngClass]="{
              'is-invalid': form.get('deliveryAddress.address')?.invalid,
              'is-valid': form.get('deliveryAddress.address')?.valid
            }"
          />
          <div
            *ngIf="form.get('deliveryAddress.address')?.invalid"
            class="text-danger"
          >
            Adres is verplicht.
          </div>
        </div>
        <div class="d-flex flex-column mb-2 w-100">
          <label class="form-label" for="postalCode"
            ><strong>Postcode</strong></label
          >
          <input
            id="postalCode"
            type="text"
            class="form-control form-control-sm"
            formControlName="postalCode"
            [ngClass]="{
              'is-invalid': form.get('deliveryAddress.postalCode')?.invalid,
              'is-valid': form.get('deliveryAddress.postalCode')?.valid
            }"
          />
          <div
            *ngIf="form.get('deliveryAddress.postalCode')?.invalid"
            class="text-danger"
          >
            Postcode is verplicht.
          </div>
        </div>
        <div class="d-flex flex-column mb-2 w-100">
          <label class="form-label" for="city"><strong>Plaats</strong></label>
          <input
            id="city"
            type="text"
            class="form-control form-control-sm"
            formControlName="city"
            [ngClass]="{
              'is-invalid': form.get('deliveryAddress.city')?.invalid,
              'is-valid': form.get('deliveryAddress.city')?.valid
            }"
          />
          <div
            *ngIf="form.get('deliveryAddress.city')?.invalid"
            class="text-danger"
          >
            Plaats is verplicht.
          </div>
        </div>
        <div class="d-flex flex-column mb-2 w-100">
          <label for="country" class="form-label">
            <strong>Land</strong>
          </label>
          <select
            id="country"
            class="form-select form-select-sm"
            formControlName="country"
            [ngClass]="{
              'is-invalid': form.get('deliveryAddress.country')?.invalid,
              'is-valid': form.get('deliveryAddress.country')?.valid
            }"
          >
            <option [value]="''">Selecteer een land</option>
            <option [value]="'NL'">Nederland (NL)</option>
            <option [value]="'DE'">Duitsland (DE)</option>
          </select>
          <div
            *ngIf="form.get('deliveryAddress.country')?.hasError('required')"
            class="text-danger"
          >
            Land is verplicht.
          </div>
          <div
            *ngIf="
              form.get('deliveryAddress.country')?.hasError('invalidCountry')
            "
            class="text-danger"
          >
            Land moet Nederland (NL) of Duitsland (DE) zijn.
          </div>
        </div>
        <div class="d-flex flex-column mb-2 w-100">
          <label class="form-label" for="telephoneNumber"
            ><strong>Telefoonnummer</strong></label
          >
          <input
            id="telephoneNumber"
            type="text"
            class="form-control form-control-sm"
            formControlName="telephoneNumber"
            [ngClass]="{
              'is-invalid': form.get('deliveryAddress.telephoneNumber')
                ?.invalid,
              'is-valid': form.get('deliveryAddress.telephoneNumber')?.valid
            }"
          />
          <div
            *ngIf="form.get('deliveryAddress.telephoneNumber')?.invalid"
            class="text-danger"
          >
            Telefoonnummer is verplicht.
          </div>
        </div>
        <div class="d-flex flex-column mb-2 w-100">
          <label class="form-label" for="email" class="form-label"
            ><strong>E-mailadres</strong></label
          >
          <input
            id="email"
            type="text"
            class="form-control form-control-sm"
            formControlName="email"
            [ngClass]="{
              'is-invalid': form.get('deliveryAddress.email')?.invalid,
              'is-valid': form.get('deliveryAddress.email')?.valid
            }"
          />
          <div
            *ngIf="form.get('deliveryAddress.email')?.invalid"
            class="text-danger"
          >
            E-mailadres is verplicht.
          </div>
        </div>
        <div
          class="d-flex flex-column mb-2 w-100"
          *ngIf="isNightShippingMethod()"
        >
          <strong>Dit was een order met nightshipping</strong>
          <div class="form-check">
            <input
              id="switchToDayShipping"
              type="checkbox"
              class="form-check-input"
              formControlName="switchToDayShipping"
              (change)="onSwitchToDayShippingChange()"
            />
            <label for="switchToDayShipping" class="form-check-label">
              <strong>Verzenden met daglevering</strong>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex h-100 mt-3">
    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="form.invalid || invalidOrderlinesAndItems()"
    >
      <i class="fa-regular fa-shopping-cart fa-l"></i> Manco aanmaken
    </button>
  </div>
</form>
