<form [formGroup]="form" (ngSubmit)="onSubmit()" class="needs-validation">
  <div class="dialog-body py-3 px-4">
    <!-- Freshdesk Ticket Number -->
    <div class="mb-3 row">
      <label for="ticketnummer" class="col-sm-4 col-form-label text-end"
        >Freshdesk ticket:</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control manco-form-control"
          id="ticketnummer"
          formControlName="ticketNumber"
          #ticketNumberInput
          [ngClass]="getValidationClass('ticketNumber')"
        />
        <div
          *ngIf="
            form.get('ticketNumber')?.invalid &&
            (form.get('ticketNumber')?.dirty ||
              form.get('ticketNumber')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('ticketNumber')?.errors?.['required']">
            Freshdesk ticket is verplicht.
          </div>
          <div *ngIf="form.get('ticketNumber')?.errors?.['pattern']">
            Freshdesk ticket mogen alleen cijfers zijn.
          </div>
        </div>
      </div>
    </div>

    <!-- Client Number -->
    <div class="mb-3 row">
      <label for="klantnummer" class="col-sm-4 col-form-label text-end"
        >Klantnummer:</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control manco-form-control"
          id="klantnummer"
          formControlName="customerNumber"
          [ngClass]="getValidationClass('customerNumber')"
        />
        <div
          *ngIf="
            form.get('customerNumber')?.invalid &&
            (form.get('customerNumber')?.dirty ||
              form.get('customerNumber')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('customerNumber')?.errors?.['maxlength']">
            Klantnummer mag niet langer zijn dan 20.
          </div>
          <div *ngIf="form.get('customerNumber')?.errors?.['pattern']">
            Klantnummer moet een INDI klantnummer zijn.
          </div>
        </div>
      </div>
    </div>

    <!-- Kramp case -->
    <div class="mb-3 row">
      <label for="case" class="col-sm-4 col-form-label text-end"
        >Kramp case:</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control manco-form-control"
          id="case"
          formControlName="caseNumber"
          [ngClass]="getValidationClass('caseNumber')"
        />
        <div
          *ngIf="
            form.get('caseNumber')?.invalid &&
            (form.get('caseNumber')?.dirty || form.get('caseNumber')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('caseNumber')?.errors?.['maxlength']">
            Kramp case mag niet langer zijn van 20.
          </div>
        </div>
      </div>
    </div>

    <!-- Treatment Date -->
    <div class="mb-3 row">
      <label for="behandeldatum" class="col-sm-4 col-form-label text-end"
        >Behandeldatum:</label
      >
      <div class="col-sm-8">
        <input
          type="date"
          max="2030-12-31"
          class="form-control manco-form-control"
          id="behandeldatum"
          formControlName="treatmentDate"
          [ngClass]="getValidationClass('treatmentDate')"
        />
        <div
          *ngIf="
            form.get('treatmentDate')?.invalid &&
            (form.get('treatmentDate')?.dirty ||
              form.get('treatmentDate')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('treatmentDate')?.errors?.['required']">
            Behandeldatum is verplicht.
          </div>
        </div>
      </div>
    </div>

    <!-- Review Date -->
    <div class="mb-3 row">
      <label for="nakijken" class="col-sm-4 col-form-label text-end"
        >Nakijken na:</label
      >
      <div class="col-sm-8">
        <input
          type="date"
          max="2030-12-31"
          class="form-control manco-form-control"
          id="nakijken"
          formControlName="checkDate"
          [ngClass]="getValidationClass('checkDate')"
        />
        <div
          *ngIf="
            form.get('checkDate')?.invalid &&
            (form.get('checkDate')?.dirty || form.get('checkDate')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('checkDate')?.errors?.['required']">
            'Nakijken na' is verplicht.
          </div>
        </div>
      </div>
    </div>

    <!-- Original Order Number -->
    <div class="mb-3 row">
      <label for="forOrderNumber" class="col-sm-4 col-form-label text-end"
        >Origineel ordernummer:</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            type="text"
            class="form-control manco-form-control"
            id="forOrderNumber"
            formControlName="forOrderNumber"
            [ngClass]="getValidationClass('forOrderNumber')"
          />
          <!-- Order link -->
          <a
            *ngIf="form.get('forOrderNumber')?.value"
            [href]="form.get('forOrderNumber')?.value | freshdeskOmsOrderLink"
            target="_blank"
            class="input-group-text link-icon text-decoration-none"
            aria-label="Order Link"
          >
            <i class="fas fa-link"></i>
          </a>
        </div>

        <div
          *ngIf="
            form.get('forOrderNumber')?.invalid &&
            (form.get('forOrderNumber')?.dirty ||
              form.get('forOrderNumber')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('forOrderNumber')?.errors?.['maxlength']">
            Origineel ordernummer mag niet langer zijn dan 30.
          </div>
        </div>
      </div>
    </div>

    <!-- Manco Order Number -->
    <div class="mb-3 row">
      <label for="mancoOrderNumber" class="col-sm-4 col-form-label text-end"
        >Manco ordernummer:</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            type="text"
            class="form-control manco-form-control"
            id="mancoOrderNumber"
            formControlName="mancoOrderNumber"
            [ngClass]="getValidationClass('mancoOrderNumber')"
          />
          <!-- Order link -->
          <a
            *ngIf="form.get('mancoOrderNumber')?.value"
            [href]="form.get('mancoOrderNumber')?.value | freshdeskOmsOrderLink"
            target="_blank"
            class="input-group-text link-icon text-decoration-none"
            aria-label="Order Link"
          >
            <i class="fas fa-link"></i>
          </a>
        </div>
        <div
          *ngIf="
            form.get('mancoOrderNumber')?.invalid &&
            (form.get('mancoOrderNumber')?.dirty ||
              form.get('mancoOrderNumber')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('mancoOrderNumber')?.errors?.['maxlength']">
            Manco ordernummer mag niet langer zijn dan 30.
          </div>
        </div>
      </div>
    </div>

    <!-- Note -->
    <div class="mb-3 row">
      <label class="col-sm-4 col-form-label text-end" for="note"
        >Notitie:</label
      >
      <div class="col-sm-8">
        <textarea
          class="form-control"
          id="note"
          rows="3"
          formControlName="note"
          [ngClass]="getValidationClass('note')"
        ></textarea>

        <div
          *ngIf="form.get('note')?.touched && form.get('note')?.invalid"
          class="text-danger"
        >
          De notitie is te lang (max 255 karakters).
        </div>
      </div>
    </div>

    <!-- Kramp retour nummber -->
    <div class="mb-3 row">
      <label for="returnNo" class="col-sm-4 col-form-label text-end"
        >Kramp retournr:</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control manco-form-control"
          id="returnNo"
          formControlName="returnNumber"
          [ngClass]="getValidationClass('returnNumber')"
        />
        <div
          *ngIf="
            form.get('returnNumber')?.invalid &&
            (form.get('returnNumber')?.dirty ||
              form.get('returnNumber')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('returnNumber')?.errors?.['maxlength']">
            Kramp retournummer mag niet langer zijn dan 20.
          </div>
        </div>
      </div>
    </div>

    <!-- Checkboxes -->
    <div class="mb-3 row">
      <div class="col-sm-8 offset-sm-4">
        <div class="form-check mb-2">
          <input
            type="checkbox"
            class="form-check-input"
            id="opgeboekt"
            formControlName="booked"
          />
          <label class="form-check-label" for="opgeboekt"
            >Opgeboekt in voorraad <strong>(O)</strong></label
          >
        </div>
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="afgehandeld"
            formControlName="completed"
          />
          <label class="form-check-label" for="afgehandeld"
            >Afgehandeld <strong>(A)</strong></label
          >
        </div>
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="afgehandeld"
            formControlName="returnReceived"
          />
          <label class="form-check-label" for="afgehandeld"
            >Kramp retour ontvangen <strong>(R)</strong></label
          >
        </div>
      </div>
    </div>
  </div>

  <div
    class="dialog-footer d-flex gap-2 justify-content-end p-3 bg-light border-top"
  >
    <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
      Opslaan
    </button>
  </div>
</form>
